export const seriesId = [
  "c0_consumo_privado",
  "c0_consumo_publico",
  "c0_formacion_bruta_capital_fijo",
  "c0_variacion_existencias",
  "c0_exportaciones",
  "c0_importaciones",
  "c0_pib",
  "c1_pib",
  "c1_rentas_primarias_netas_rdm",
  "c1_rnb",
  "c1_consumo_de_capital_fijo",
  "c1_rnn",
  "c1_transferencias_corrientes_netas_rdm",
  "c1_rndn",
  "c2_pib",
  "c2_rnb",
  "c2_rnn",
  "c2_rndn",
  "c2_poblacion",
  "c2_pib_per_capita",
  "c2_rnb_per_capita",
  "c2_rnn_per_capita",
  "c2_rndn_per_capita",
  "c3_pib",
  "c3_rnb",
  "c3_rnn",
  "c3_rndn",
  "c3_pib_per_capita",
  "c3_rnb_per_capita",
  "c3_rnn_per_capita",
  "c3_rndn_per_capita",
  "c4_consumo_privado",
  "c4_consumo_publico",
  "c4_formacion_bruta_capital_fijo",
  "c4_variacion_existencias",
  "c4_exportaciones",
  "c4_importaciones",
  "c4_exp_imp",
  "c5_consumo_privado",
  "c5_consumo_publico",
  "c5_formacion_bruta_capital_fijo",
  "c5_formacion_capital",
  "c5_exportaciones",
  "c5_importaciones",
  "c5_pib",
  "c6_consumo_privado",
  "c6_consumo_publico",
  "c6_formacion_bruta_capital_fijo",
  "c6_formacion_capital",
  "c6_exportaciones",
  "c6_importaciones",
  "c6_pib",
  "c6_rnb",
  "c6_rnn",
  "c6_rndn",
  "c7_viviendas",
  "c7_otras_construcciones",
  "c7_maquinaria_y_equipo",
  "c7_equipo_de_transporte",
  "c7_formacion_bruta_capital_fijo",
  "c8_viviendas",
  "c8_otras_construcciones",
  "c8_maquinaria_y_equipo",
  "c8_equipo_de_transporte",
  "c9_viviendas",
  "c9_otras_construcciones",
  "c9_maquinaria_y_equipo",
  "c9_equipo_de_transporte",
  "c9_formacion_bruta_capital_fijo",
  "c10_viviendas",
  "c10_otras_construcciones",
  "c10_maquinaria_y_equipo",
  "c10_equipo_de_transporte",
  "c10_formacion_bruta_capital_fijo",
  "c11_agricultura",
  "c11_industria",
  "c11_construccion",
  "c11_servicios",
  "c11_valor_anadido_bruto",
  "c11_impuestos_netos_sobre_productos",
  "c11_pib",
  "c12_vab",
  "c12_pib",
  "c12_poblacion",
  "c12_vab_per_capita",
  "c12_pib_per_capita",
  "c13_vab",
  "c13_pib",
  "c13_vab_per_capita",
  "c13_pib_per_capita",
  "c14_agricultura",
  "c14_industria",
  "c14_construccion",
  "c14_servicios",
  "c15_agricultura",
  "c15_industria",
  "c15_construccion",
  "c15_servicios",
  "c15_vab",
  "c16_agricultura",
  "c16_industria",
  "c16_construccion",
  "c16_servicios",
  "c16_vab",
  "c17_agricultura",
  "c17_industria",
  "c17_construccion",
  "c17_servicios",
  "c17_total",
  "c18_agricultura",
  "c18_industria",
  "c18_construccion",
  "c18_servicios",
  "c19_agricultura",
  "c19_industria",
  "c19_construccion",
  "c19_servicios",
  "c20_agricultura",
  "c20_industria",
  "c20_construccion",
  "c20_servicios",
  "c20_vab",
  "c21_agricultura",
  "c21_industria",
  "c21_construccion",
  "c21_servicios",
  "c21_total",
  "c22_agricultura",
  "c22_industria",
  "c22_construccion",
  "c22_servicios",
  "c23_agricultura",
  "c23_industria",
  "c23_construccion",
  "c23_servicios",
  "c24_agricultura",
  "c24_industria",
  "c24_construccion",
  "c24_servicios",
  "c24_vab",
  "c25_agricultura",
  "c25_industria",
  "c25_construccion",
  "c25_servicios",
  "c25_total",
  "c26_espana",
  "c26_italia",
  "c26_francia",
  "c26_reino_unido",
  "c26_ee_uu",
  "c26_alemania",
  "c27_espana",
  "c27_italia",
  "c27_francia",
  "c27_reino_unido",
  "c27_ee_uu",
  "c27_alemania"
]

export const seriesEsp = [
  "Consumo privado",
  "Consumo público",
  "Formación bruta capital fijo",
  "Variación Existencias",
  "Exportaciones",
  "Importaciones",
  "PIB",
  "PIB (RNB)",
  "Rentas primarias netas rdm",
  "RNB (RNB)",
  "Consumo de capital fijo",
  "RNN (RNB)",
  "Transferencias corrientes netas rdm",
  "RNDN",
  "PIB (RNBcap)",
  "RNB (RNBcap)",
  "RNN (RNBcap)",
  "RNDN (RNBcap)",
  "Población",
  "PIB per capita",
  "RNB per capita",
  "RNN per capita",
  "RNDN per capita",
  "PIB (índice)",
  "RNB (índice)",
  "RNN (índice)",
  "RNDN (índice)",
  "PIB per capita (índice)",
  "RNB per capita (índice)",
  "RNN per capita (índice)",
  "RNDN per capita (índice)",
  "Consumo privado (%)",
  "Consumo público (%)",
  "Formación bruta capital fijo (%)",
  "Variación Existencias (%)",
  "Exportaciones (%)",
  "Importaciones (%)",
  "Exportaciones-Importaciones (%)",
  "Consumo privado (índice comp.)",
  "Consumo público (índice comp.)",
  "Formación bruta capital fijo (índice comp.)",
  "Formación capital (índice comp.)",
  "Exportaciones (índice comp.)",
  "Importaciones (índice comp.)",
  "PIB (índice comp.)",
  "Consumo privado (deflactores)",
  "Consumo público (deflactores)",
  "Formación bruta capital fijo (deflactores)",
  "Formación capital (deflactores)",
  "Exportaciones (deflactores)",
  "Importaciones (deflactores)",
  "PIB (deflactores)",
  "RNB (deflactores)",
  "RNN (deflactores)",
  "RNDN (deflactores)",
  "Viviendas",
  "Otras construcciones",
  "Maquinaria y equipo",
  "Equipo de transporte",
  "Formación bruta capital fijo (Total)",
  "Viviendas (Composición)",
  "Otras construcciones (%)",
  "Maquinaria y equipo (%)",
  "Equipo de transporte (%)",
  "Viviendas (Indices)",
  "Otras construcciones (Indices)",
  "Maquinaria y equipo (Indices)",
  "Equipo de transporte (Indices)",
  "Formación bruta capital fijo (Indices)",
  "Viviendas (deflactores)",
  "Otras construcciones (deflactores)",
  "Maquina y equipo (deflactores)",
  "Equipo de transporte (deflactores)",
  "Formación brutal capital fijo (deflactores)",
  "Agricultura",
  "Industria",
  "Construcción",
  "Servicios",
  "Valor Añadido Bruto",
  "Impuestos netos sobre productos",
  "PIB (mill)",
  "VAB (mill)",
  "PIB (VA mill)",
  "Población (VA mill)",
  "VAB per capita (precios mercado)",
  "PIB per capita (precios mercado)",
  "VAB (índice vol)",
  "PIB (índice vol)",
  "VAB per capita (índice vol)",
  "PIB per capita (índice vol)",
  "Agricultura (%)",
  "Industria (%)",
  "Construcción (%)",
  "Servicios (%)",
  "Agricultura (índice)",
  "Industria (índice)",
  "Construcción (índice)",
  "Servicios (índice)",
  "VAB (índice)",
  "Agricultura (deflactores)",
  "Industria (deflactores)",
  "Construcción (deflactores)",
  "Servicios (deflactores)",
  "VAB (deflactores)",
  "Agricultura (empleo)",
  "Industria (empleo)",
  "Construcción (empleo)",
  "Servicios (empleo)",
  "Total (empleo)",
  "Agricultura (participación)",
  "Industria (participación)",
  "Construcción (participación)",
  "Servicios (participación)",
  "Agricultura (VAB)",
  "Industria (VAB)",
  "Construcción (VAB)",
  "Servicios (VAB)",
  "Agricultura (índice prod.)",
  "Industria (índice prod.)",
  "Construcción (índice prod.)",
  "Servicios (índice prod.)",
  "VAB (índice prod.)",
  "Agricultura: horas trabajadas",
  "Industria: horas trabajadas",
  "Construcción: horas trabajadas",
  "Servicios: horas trabajadas",
  "Total horas trabajadas",
  "Agricultura (partic. horas trabajadas)",
  "Industria (partic. horas trabajadas)",
  "Construcción (partic. horas trabajadas)",
  "Servicios (partic. horas trabajadas)",
  "Agricultura (VAB/hora trabajada)",
  "Industria (VAB/hora trabajada)",
  "Construcción (VAB/hora trabajada)",
  "Servicios (VAB/hora trabajada)",
  "Agricultura (nivel productividad)",
  "Industria (nivel productividad)",
  "Construcción (nivel productividad)",
  "Servicios (nivel productividad)",
  "VAB (nivel productividad)",
  "Agricultura (horas trabajadas trabajador)",
  "Industria (horas trabajadas trabajador)",
  "Construcción (horas trabajadas trabajador)",
  "Servicios (horas trabajadas trabajador)",
  "Total (horas trabajadas trabajador)",
  "España (PIB)",
  "Italia (PIB)",
  "Francia (PIB)",
  "Reino Unido (PIB)",
  "EE.UU. (PIB)",
  "Alemania (PIB)",
  "España (PIB geary-khamis)",
  "Italia (PIB geary-khamis)",
  "Francia (PIB geary-khamis)",
  "Reino Unido (PIB geary-khamis)",
  "EE.UU. (PIB geary-khamis)",
  "Alemania (PIB geary-khamis)"
]

export const seriesEng = [
  "Private consumption",
  "Public consumption",
  "Gross fixed capital formation",
  "Variation in Inventories",
  "Exports",
  "Imports",
  "GDP",
  "GDP (GNI)",
  "Net primary income rdm",
  "GNI (GNI)",
  "Consumption of fixed capital",
  "NNI (GNI)",
  "Net current transfers rdm",
  "NNDI",
  "GDP (GNIcap)",
  "GNI (GNIcap)",
  "NNI (GNIcap)",
  "NNDI (GNIcap)",
  "Population",
  "GDP per capita",
  "GNI per capita",
  "NNI per capita",
  "NNDI per capita",
  "GDP (index)",
  "GNI (index)",
  "NNI (index)",
  "NNDI (index)",
  "GDP per capita (index)",
  "GNI per capita (index)",
  "NNI per capita (index)",
  "NNDI per capita (index)",
  "Private consumption (%)",
  "Public consumption (%)",
  "Gross fixed capital formation (%)",
  "Variation in Inventories (%)",
  "Exports (%)",
  "Imports (%)",
  "Exports-Imports (%)",
  "Private consumption (index comp.)",
  "Public consumption (index comp.)",
  "Gross fixed capital formation (index comp.)",
  "Capital formation (index comp.)",
  "Exports (index comp.)",
  "Imports (index comp.)",
  "GDP (index comp.)",
  "Private consumption (deflators)",
  "Public consumption (deflators)",
  "Gross fixed capital formation (deflators)",
  "Capital formation (deflators)",
  "Exports (deflators)",
  "Imports (deflators)",
  "GDP (deflators)",
  "GNI (deflators)",
  "NNI (deflators)",
  "NNDI (deflators)",
  "Dwellings",
  "Other constructions",
  "Machinery and equipment",
  "Transport equipment",
  "Gross fixed capital formation (Total)",
  "Dwellings (Composition)",
  "Other constructions (%)",
  "Machinery and equipment (%)",
  "Transport equipment (%)",
  "Dwellings (Indexes)",
  "Other constructions (Indexes)",
  "Machinery and equipment (Indexes)",
  "Transport equipment (Indexes)",
  "Gross fixed capital formation (Indexes)",
  "Dwellings (deflators)",
  "Other constructions (deflators)",
  "Machinery and equipment (deflators)",
  "Transport equipment (deflators)",
  "Gross fixed capital formation (deflators)",
  "Agriculture",
  "Industry",
  "Construction",
  "Services",
  "Gross value added",
  "Net taxes on products",
  "GDP (mill)",
  "GVA (mill)",
  "GDP (VA mill)",
  "Population (VA mill)",
  "GVA per capita (market prices)",
  "GDP per capita (market prices)",
  "GVA (index vol)",
  "GDP (index vol)",
  "GVA per capita (index vol)",
  "GDP per capita (index vol)",
  "Agriculture (%)",
  "Industry (%)",
  "Construction (%)",
  "Services (%)",
  "Agriculture (index)",
  "Industry (index)",
  "Construction (index)",
  "Services (index)",
  "GVA (index)",
  "Agriculture (deflators)",
  "Industry (deflators)",
  "Construction (deflators)",
  "Services (deflators)",
  "GVA (deflators)",
  "Agriculture (employment)",
  "Industry (employment)",
  "Construction (employment)",
  "Services (employment)",
  "Total (employment)",
  "Agriculture (participation)",
  "Industry (participation)",
  "Construction (participation)",
  "Services (participation)",
  "Agriculture (GVA)",
  "Industry (GVA)",
  "Construction (GVA)",
  "Services (GVA)",
  "Agriculture (index prod.)",
  "Industry (index prod.)",
  "Construction (index prod.)",
  "Services (index prod.)",
  "GVA (index prod.)",
  "Agriculture: hours worked",
  "Industry: hours worked",
  "Construction: hours worked",
  "Services: hours worked",
  "Total hours worked",
  "Agriculture (partic. hours worked)",
  "Industry (partic. hours worked)",
  "Construction (partic. hours worked)",
  "Services (partic. hours worked)",
  "Agriculture (GVA/hour worked)",
  "Industry (GVA/hour worked)",
  "Construction (GVA/hour worked)",
  "Services (GVA/hour worked)",
  "Agriculture (productivity level)",
  "Industry (productivity level)",
  "Construction (productivity level)",
  "Services (productivity level)",
  "GVA (productivity level)",
  "Agriculture (hours worked worker)",
  "Industry (hours worked worker)",
  "Construction (hours worked worker)",
  "Services (hours worked worker)",
  "Total (hours worked worker)",
  "Spain (GDP)",
  "Italy (GDP)",
  "France (GDP)",
  "United Kingdom (GDP)",
  "USA (GDP)",
  "Germany (GDP)",
  "Spain (GDP geary-khamis)",
  "Italy (GDP geary-khamis)",
  "France (GDP geary-khamis)",
  "United Kingdom (GDP geary-khamis)",
  "USA (GDP geary-khamis)",
  "Germany (GDP geary-khamis)"
]

export const groupsEsp = [
  "El Producto Interior Bruto y sus componentes de gasto",
  "El Producto Interior Bruto y sus componentes de gasto",
  "El Producto Interior Bruto y sus componentes de gasto",
  "El Producto Interior Bruto y sus componentes de gasto",
  "El Producto Interior Bruto y sus componentes de gasto",
  "El Producto Interior Bruto y sus componentes de gasto",
  "El Producto Interior Bruto y sus componentes de gasto",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita",
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta" ,
  "Participación de los componentes de gasto en el Producto Interior Bruto",
  "Participación de los componentes de gasto en el Producto Interior Bruto",
  "Participación de los componentes de gasto en el Producto Interior Bruto",
  "Participación de los componentes de gasto en el Producto Interior Bruto",
  "Participación de los componentes de gasto en el Producto Interior Bruto",
  "Participación de los componentes de gasto en el Producto Interior Bruto",
  "Participación de los componentes de gasto en el Producto Interior Bruto",
  "Índices de volumen del Producto Interior Bruto y sus componentes de gasto",
  "Índices de volumen del Producto Interior Bruto y sus componentes de gasto",
  "Índices de volumen del Producto Interior Bruto y sus componentes de gasto",
  "Índices de volumen del Producto Interior Bruto y sus componentes de gasto",
  "Índices de volumen del Producto Interior Bruto y sus componentes de gasto",
  "Índices de volumen del Producto Interior Bruto y sus componentes de gasto",
  "Índices de volumen del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Deflactores del Producto Interior Bruto y sus componentes de gasto",
  "Formación Bruta de Capital Fijo",
  "Formación Bruta de Capital Fijo",
  "Formación Bruta de Capital Fijo",
  "Formación Bruta de Capital Fijo",
  "Formación Bruta de Capital Fijo",
  "Composición de la Formación Bruta de Capital Fijo",
  "Composición de la Formación Bruta de Capital Fijo",
  "Composición de la Formación Bruta de Capital Fijo",
  "Composición de la Formación Bruta de Capital Fijo",
  "Índices de volumen de la Formación Bruta de Capital Fijo",
  "Índices de volumen de la Formación Bruta de Capital Fijo",
  "Índices de volumen de la Formación Bruta de Capital Fijo",
  "Índices de volumen de la Formación Bruta de Capital Fijo",
  "Índices de volumen de la Formación Bruta de Capital Fijo",
  "Deflactores de la Formación Bruta de Capital Fijo",
  "Deflactores de la Formación Bruta de Capital Fijo",
  "Deflactores de la Formación Bruta de Capital Fijo",
  "Deflactores de la Formación Bruta de Capital Fijo",
  "Deflactores de la Formación Bruta de Capital Fijo",
  "El Producto Interior Bruto y sus componentes de producto",
  "El Producto Interior Bruto y sus componentes de producto",
  "El Producto Interior Bruto y sus componentes de producto",
  "El Producto Interior Bruto y sus componentes de producto",
  "El Producto Interior Bruto y sus componentes de producto",
  "El Producto Interior Bruto y sus componentes de producto",
  "El Producto Interior Bruto y sus componentes de producto",
  "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante",
  "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante",
  "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante",
  "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante",
  "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante",
  "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor",
  "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor",
  "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor",
  "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor",
  "Participación de las ramas de actividad en el Valor Añadido Bruto",
  "Participación de las ramas de actividad en el Valor Añadido Bruto",
  "Participación de las ramas de actividad en el Valor Añadido Bruto",
  "Participación de las ramas de actividad en el Valor Añadido Bruto",
  "Índices de volumen de Valor Añadido Bruto y sus componentes",
  "Índices de volumen de Valor Añadido Bruto y sus componentes",
  "Índices de volumen de Valor Añadido Bruto y sus componentes",
  "Índices de volumen de Valor Añadido Bruto y sus componentes",
  "Índices de volumen de Valor Añadido Bruto y sus componentes",
  "Deflactores del Valor Añadido Bruto y sus componentes",
  "Deflactores del Valor Añadido Bruto y sus componentes",
  "Deflactores del Valor Añadido Bruto y sus componentes",
  "Deflactores del Valor Añadido Bruto y sus componentes",
  "Deflactores del Valor Añadido Bruto y sus componentes",
  "Empleo (equivalente a tiempo completo)",
  "Empleo (equivalente a tiempo completo)",
  "Empleo (equivalente a tiempo completo)",
  "Empleo (equivalente a tiempo completo)",
  "Empleo (equivalente a tiempo completo)",
  "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)",
  "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)",
  "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)",
  "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)",
  "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)",
  "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)",
  "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)",
  "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)",
  "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)",
  "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)",
  "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)",
  "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)",
  "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)",
  "Horas trabajadas",
  "Horas trabajadas",
  "Horas trabajadas",
  "Horas trabajadas",
  "Horas trabajadas",
  "Participación por ramas de actividad en las horas trabajadas",
  "Participación por ramas de actividad en las horas trabajadas",
  "Participación por ramas de actividad en las horas trabajadas",
  "Participación por ramas de actividad en las horas trabajadas",
  "Productividad relativa del trabajo por ramas de actividad",
  "Productividad relativa del trabajo por ramas de actividad",
  "Productividad relativa del trabajo por ramas de actividad",
  "Productividad relativa del trabajo por ramas de actividad",
  "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)",
  "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)",
  "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)",
  "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)",
  "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)",
  "Horas trabajadas por trabajador equivalente a tiempo completo /año",
  "Horas trabajadas por trabajador equivalente a tiempo completo /año",
  "Horas trabajadas por trabajador equivalente a tiempo completo /año",
  "Horas trabajadas por trabajador equivalente a tiempo completo /año",
  "Horas trabajadas por trabajador equivalente a tiempo completo /año",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 2011)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 2011)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 2011)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 2011)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 2011)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 2011)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 1990)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 1990)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 1990)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 1990)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 1990)",
  "Producto Interior Bruto Real per capita (dólares Geary-Khamis de 1990)"
]

export const groupsEng = [
  "The Gross Domestic Product and its spending components",
  "The Gross Domestic Product and its spending components",
  "The Gross Domestic Product and its spending components",
  "The Gross Domestic Product and its spending components",
  "The Gross Domestic Product and its spending components",
  "The Gross Domestic Product and its spending components",
  "The Gross Domestic Product and its spending components",
  "Gross Domestic Product and Gross and Net National Income",
  "Gross Domestic Product and Gross and Net National Income",
  "Gross Domestic Product and Gross and Net National Income",
  "Gross Domestic Product and Gross and Net National Income",
  "Gross Domestic Product and Gross and Net National Income",
  "Gross Domestic Product and Gross and Net National Income",
  "Gross Domestic Product and Gross and Net National Income",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Gross Domestic Product and Gross and Net National Income absolute and per capita",
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "Volume indices of Gross Domestic Product and Gross and Net National Income" ,
  "GDP and its expenditure components",
  "GDP and its expenditure components",
  "GDP and its expenditure components",
  "GDP and its expenditure components",
  "GDP and its expenditure components",
  "GDP and its expenditure components",
  "GDP and its expenditure components",
  "Volume Indices of Gross Domestic Product and its Expenditure Components",
  "Volume Indices of Gross Domestic Product and its Expenditure Components",
  "Volume Indices of Gross Domestic Product and its Expenditure Components",
  "Volume Indices of Gross Domestic Product and its Expenditure Components",
  "Volume Indices of Gross Domestic Product and its Expenditure Components",
  "Volume Indices of Gross Domestic Product and its Expenditure Components",
  "Volume Indices of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Deflators of Gross Domestic Product and its Expenditure Components",
  "Gross Fixed Capital Formation",
  "Gross Fixed Capital Formation",
  "Gross Fixed Capital Formation",
  "Gross Fixed Capital Formation",
  "Gross Fixed Capital Formation",
  "Composition of Gross Fixed Capital Formation",
  "Composition of Gross Fixed Capital Formation",
  "Composition of Gross Fixed Capital Formation",
  "Composition of Gross Fixed Capital Formation",
  "Gross fixed capital formation, volume indices",
  "Gross fixed capital formation, volume indices",
  "Gross fixed capital formation, volume indices",
  "Gross fixed capital formation, volume indices",
  "Gross fixed capital formation, volume indices",
  "Gross fixed capital formation deflator",
  "Gross fixed capital formation deflator",
  "Gross fixed capital formation deflator",
  "Gross fixed capital formation deflator",
  "Gross fixed capital formation deflator",
  "Components of Gross Domestic Product",
  "Components of Gross Domestic Product",
  "Components of Gross Domestic Product",
  "Components of Gross Domestic Product",
  "Components of Gross Domestic Product",
  "Components of Gross Domestic Product",
  "Components of Gross Domestic Product",
  "Gross Value Added and Gross Domestic Product at absolute market prices and per inhabitant",
  "Gross Value Added and Gross Domestic Product at absolute market prices and per inhabitant",
  "Gross Value Added and Gross Domestic Product at absolute market prices and per inhabitant",
  "Gross Value Added and Gross Domestic Product at absolute market prices and per inhabitant",
  "Gross Value Added and Gross Domestic Product at absolute market prices and per inhabitant",
  "Gross Domestic Product volume indices at market prices and Value",
  "Gross Domestic Product volume indices at market prices and Value",
  "Gross Domestic Product volume indices at market prices and Value",
  "Gross Domestic Product volume indices at market prices and Value",
  "Participation of the branches of activity in the Gross Value Added ",
  "Participation of the branches of activity in the Gross Value Added ",
  "Participation of the branches of activity in the Gross Value Added ",
  "Participation of the branches of activity in the Gross Value Added ",
  "Gross Value Added volume indices and its components",
  "Gross Value Added volume indices and its components",
  "Gross Value Added volume indices and its components",
  "Gross Value Added volume indices and its components",
  "Gross Value Added volume indices and its components",
  "Deflators of Gross Value Added and its components",
  "Deflators of Gross Value Added and its components",
  "Deflators of Gross Value Added and its components",
  "Deflators of Gross Value Added and its components",
  "Deflators of Gross Value Added and its components",
  "Employment (full-time equivalent)",
  "Employment (full-time equivalent)",
  "Employment (full-time equivalent)",
  "Employment (full-time equivalent)",
  "Employment (full-time equivalent)",
  "Participation by branches of activity in employment (full-time equivalent)",
  "Participation by branches of activity in employment (full-time equivalent)",
  "Participation by branches of activity in employment (full-time equivalent)",
  "Participation by branches of activity in employment (full-time equivalent)",
  "Relative labour productivity by branches of activity (GVA / full-time equivalent worker)",
  "Relative labour productivity by branches of activity (GVA / full-time equivalent worker)",
  "Relative labour productivity by branches of activity (GVA / full-time equivalent worker)",
  "Relative labour productivity by branches of activity (GVA / full-time equivalent worker)",
  "Labor productivity indices (Gross Value Added per full-time equivalent worker)",
  "Labor productivity indices (Gross Value Added per full-time equivalent worker)",
  "Labor productivity indices (Gross Value Added per full-time equivalent worker)",
  "Labor productivity indices (Gross Value Added per full-time equivalent worker)",
  "Labor productivity indices (Gross Value Added per full-time equivalent worker)",
  "Hours worked",
  "Hours worked",
  "Hours worked",
  "Hours worked",
  "Hours worked",
  "Participation by branches of activity in hours worked",
  "Participation by branches of activity in hours worked",
  "Participation by branches of activity in hours worked",
  "Participation by branches of activity in hours worked",
  "Relative labor productivity by branches of activity",
  "Relative labor productivity by branches of activity",
  "Relative labor productivity by branches of activity",
  "Relative labor productivity by branches of activity",
  "Labor productivity levels (Gross Value Added per hour worked)",
  "Labor productivity levels (Gross Value Added per hour worked)",
  "Labor productivity levels (Gross Value Added per hour worked)",
  "Labor productivity levels (Gross Value Added per hour worked)",
  "Labor productivity levels (Gross Value Added per hour worked)",
  "Hours worked per full-time equivalent worker/year",
  "Hours worked per full-time equivalent worker/year",
  "Hours worked per full-time equivalent worker/year",
  "Hours worked per full-time equivalent worker/year",
  "Hours worked per full-time equivalent worker/year",
  "Real GDP per capita (Geary-Khamis dollars of 2011)",
  "Real GDP per capita (Geary-Khamis dollars of 2011)",
  "Real GDP per capita (Geary-Khamis dollars of 2011)",
  "Real GDP per capita (Geary-Khamis dollars of 2011)",
  "Real GDP per capita (Geary-Khamis dollars of 2011)",
  "Real GDP per capita (Geary-Khamis dollars of 2011)",
  "Real GDP per capita (Geary-Khamis dollars of 1990)",
  "Real GDP per capita (Geary-Khamis dollars of 1990)",
  "Real GDP per capita (Geary-Khamis dollars of 1990)",
  "Real GDP per capita (Geary-Khamis dollars of 1990)",
  "Real GDP per capita (Geary-Khamis dollars of 1990)",
  "Real GDP per capita (Geary-Khamis dollars of 1990)"
]

export const unitsEsp = [
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones",
  "euros",
  "euros",
  "euros",
  "euros",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "%",
  "%",
  "%",
  "%",
  "%",
  "%",
  "%",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "%",
  "%",
  "%",
  "%",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones de euros",
  "millones",
  "Euro",
  "Euro",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "%",
  "%",
  "%",
  "%",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millones",
  "millones",
  "millones",
  "millones",
  "millones",
  "%",
  "%",
  "%",
  "%",
  "VAB / trabajador equivalente a tiempo completo",
  "VAB / trabajador equivalente a tiempo completo",
  "VAB / trabajador equivalente a tiempo completo",
  "VAB / trabajador equivalente a tiempo completo",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millones",
  "millones",
  "millones",
  "millones",
  "millones",
  "%",
  "%",
  "%",
  "%",
  "VAB/hora trabajada",
  "VAB/hora trabajada",
  "VAB/hora trabajada",
  "VAB/hora trabajada",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "horas",
  "horas",
  "horas",
  "horas",
  "horas",
  "dólares GEKS de 2011",
  "dólares GEKS de 2011",
  "dólares GEKS de 2011",
  "dólares GEKS de 2011",
  "dólares GEKS de 2011",
  "dólares GEKS de 2011",
  "dólares Geary-Khamis de 1990",
  "dólares Geary-Khamis de 1990",
  "dólares Geary-Khamis de 1990",
  "dólares Geary-Khamis de 1990",
  "dólares Geary-Khamis de 1990",
  "dólares Geary-Khamis de 1990"
]

export const unitsEng = [
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "million",
  "euros",
  "euros",
  "euros",
  "euros",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "%",
  "%",
  "%",
  "%",
  "%",
  "%",
  "%",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "%",
  "%",
  "%",
  "%",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions Euro",
  "millions",
  "Euro",
  "Euro",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "%",
  "%",
  "%",
  "%",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millions",
  "millions",
  "millions",
  "millions",
  "millions",
  "%",
  "%",
  "%",
  "%",
  "GVA / full-time equivalent worker",
  "GVA / full-time equivalent worker",
  "GVA / full-time equivalent worker",
  "GVA / full-time equivalent worker",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "millions",
  "millions",
  "millions",
  "millions",
  "millions",
  "%",
  "%",
  "%",
  "%",
  "GVA / hour worked",
  "GVA / hour worked",
  "GVA / hour worked",
  "GVA / hour worked",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "2010=100",
  "hours",
  "hours",
  "hours",
  "hours",
  "hours",
  "GEKS dollars 2011",
  "GEKS dollars 2011",
  "GEKS dollars 2011",
  "GEKS dollars 2011",
  "GEKS dollars 2011",
  "GEKS dollars 2011",
  "Geary-Khamis dollars 1990",
  "Geary-Khamis dollars 1990",
  "Geary-Khamis dollars 1990",
  "Geary-Khamis dollars 1990",
  "Geary-Khamis dollars 1990",
  "Geary-Khamis dollars 1990"
]
