export function translateSeries(seriesData, seriesEsp, seriesEng, seriesId, unitsEsp = [], unitsEng = [], groupsEsp = [], groupsEng = []) {
  const localeWeb = document.documentElement.lang

  seriesData.map(d => {
    const { id } = d
    const indexSeries = seriesId.findIndex(series => series === id);

    if (seriesEsp) {
      const selectedLanguage = localeWeb === 'es' ? seriesEsp : seriesEng
      d.name = selectedLanguage[indexSeries]
    }

    if (unitsEsp) {
      const selectedLanguage = localeWeb === 'es' ? unitsEsp : unitsEng
      d.units = selectedLanguage[indexSeries]
    }

    if (groupsEsp) {
      const selectedLanguage = localeWeb === 'es' ? groupsEsp : groupsEng
      d.group = selectedLanguage[indexSeries]
    }

    d.inverted = d.inverted || false

    return d
  })

  return seriesData
}
